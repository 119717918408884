<template>
  <!-- <client-only> -->
  <!-- <NuxtLayout> -->
  <SharedLoadingSpinner
    v-if="
      isLoading &&
      route.path !== '/cart/order_status' &&
      !route.path.includes('/auth/activate-account/token')
    "
  />
  <TheHeader />
  <!-- <v-main> -->
  <NuxtPage />
  <!-- </v-main> -->
  <TheFooter />
  <!-- </NuxtLayout> -->
  <!-- </client-only> -->
</template>

<script setup>
import { useRuntimeConfig, useHead } from "#imports";
import { useRouter } from "vue-router";
import { useState } from "#app";
// Create a loading state
const isLoading = useState("isLoading", () => false);

const route = useRoute();
const router = useRouter();

// Listen to route changes to toggle the loading state
router.beforeEach(() => {
  isLoading.value = true;
});

router.afterEach(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 1500);
});

const config = useRuntimeConfig();
const isProduction = computed(() => config.public.env === "production");
useHead({
  meta: [
    {
      name: "robots",
      content: isProduction ? "index, follow" : "noindex",
    },
  ],
});

function throwError() {
  throw new Error("Sentry Error");
}
</script>
<style></style>
